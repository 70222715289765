export function resourceType(type) {
    const resourceInfo = {}
    switch (type + '') {
        case '1': // 文档
        case '7': // pdf
            resourceInfo.name = '文档'
            resourceInfo.className = 'office'
            resourceInfo.url = require('../assets/resources/word.svg')
            break
        case '2': // 视频
            resourceInfo.name = '视频'
            resourceInfo.className = 'video'
            resourceInfo.url = require('../assets/resources/video.svg')
            break
        case '3': // 图片
            resourceInfo.name = '图片'
            resourceInfo.className = 'image'
            resourceInfo.url = require('../assets/resources/image.svg')
            break
        case '4': // 音频
            resourceInfo.name = '音频'
            resourceInfo.className = 'music'
            resourceInfo.url = require('../assets/resources/audio.svg')
            break
        case '5': // ppt
            resourceInfo.name = 'PPT'
            resourceInfo.className = 'office'
            resourceInfo.url = require('../assets/resources/ppt.svg')
            break
        case '6': // 富文本
            resourceInfo.name = '富文本'
            resourceInfo.className = 'rich-text'
            resourceInfo.url = require('../assets/resources/txt.svg')
            break
        default:
            // 其他
            resourceInfo.name = '其他'
            resourceInfo.className = 'other'
            resourceInfo.url = require('../assets/resources/txt.svg')
            break
    }
    return resourceInfo
}
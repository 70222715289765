<template>
  <div class="footer-view flex flex-j-sa">
    <div class="flex-c align-center">
      <div class="hyperlink-list fs14">
        <div v-for="(item, index) in hyperLinkList" :key="index">
          <a :href="item.url" target="“_blank”">{{ item.title }}</a>
        </div>
      </div>
      <div class="flex fs22 m-t-20">
        <div class="m-r-20">子与龙齐</div>
        <div>青云平步</div>
      </div>
      <div class="flex fs14 m-b-20">
        <div class="m-r-8">ZIYULONGQI</div>
        <div>QINGYUNPINGBU</div>
      </div>
      <div class="hyperlink-list fs14">
        <div>Copyright 2023-现在 齐龙科技有限公司</div>
        <div><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021012541号-2</a></div>
      </div>
      <div class="hyperlink-list fs14 m-t-8">
        地址：成都市高新区花样年香年广场T2写字楼3001
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hyperLinkList: [
        {
          title: "关于我们",
          url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
        },
        {
          title: "联系我们",
          url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
        },
        {
          title: "用户协议",
          url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
        },
        {
          title: "隐私政策",
          url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
        },
        {
          title: "帮助与反馈",
          url: "https://www.qilongkj.com/cover-t02v920g/service_support",
        },
        {
          title: "侵权投诉",
          url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-view {
  color: #b3b3b3;
  background-color: $fontColor;
  padding: 40px 0;
  .hyperlink-list {
    div {
      display: inline-block;
      padding: 0 20px;
      > a {
        text-decoration: none;
        color: inherit;
      }
    }
    div:not(:last-child) {
      border-right: 1px solid #b3b3b3;
    }
  }
}
</style>

<template>
    <div :class="scrollTop > 600 ?'fiexd':isTransparent ?'home-page' :'normal'">
        <div class="flex flex-j-sb align-center hader-view" :class="$themeStyle($store.state.theme.theme)">
            <div class="page-title cu-point" @click="backHome">
                青云平步
            </div>
            <div v-show="scrollTop > 600 || !isTransparent">
                <HomePageSearch :isNomarl="false" />
            </div>
            <div class="header-right flex align-center">
                <div class="model-box cu-point flex align-center m-r-20  fs14" @click="changeThemColor(isDark ? '0' : '1')">
                    <div v-show="!isDark" class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-sunrise"></i>
                    </div>
                    <div v-show="isDark" class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-sunset"></i>
                    </div>
                    {{ isDark ? "白天模式" : "黑夜模式" }}
                </div>
                <div class="model-box cu-point flex align-center m-r-20 fs14" @click="jumpTo">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-mobile-phone"></i>
                    </div>
                    APP下载
                </div>
                <div class="model-box cu-point flex align-center fs14" @click="login" v-if="!user">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-user"></i>
                    </div>
                    <span>登录丨注册</span>
                </div>
                <div class="model-box cu-point flex align-center m-r-20 fs14" @click="myCenter" v-if="user">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-user"></i>
                    </div>
                    <span>个人中心</span>
                </div>
                <div class="model-box cu-point flex align-center fs14" v-if="user">
                    <el-dropdown trigger="click">
                        <div class="flex align-center">
                            <el-image class="m-r-8" style="width: 30px; height: 30px;border-radius:30px" src="http://192.168.101.2:9090/documents/a47173d8172f1a7b3356ccd37dce6a40.gif" fit="cover"></el-image>
                            <span>用户名</span>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>个人资料</el-dropdown-item>
                            <el-dropdown-item>安全设置</el-dropdown-item>
                            <el-dropdown-item divided>退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HomePageSearch from "../views/home/components/HomePageSearch.vue";
export default {
    components: {
        HomePageSearch,
    },
    props: {
        scrollTop: {
            type: Number,
            default: 0,
        },
        isTransparent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDark: 0,
            user: {},
        };
    },
    created() {},
    mounted() {
        // console.log('this.$store.state.user.user',this.$store.state.user.user);
        this.user = JSON.parse(JSON.stringify(this.$store.state.user.user));
        console.log("this.user", this.user);
    },
    methods: {
        changeThemColor(e) {
            this.isDark = parseInt(e);
            this.$store.commit("theme/setTheme", e);
        },
        jumpTo() {
            this.$router.push("/message");
        },
        login() {
            this.$router.push("/login");
            this.$nextTick(() => {
                this.$store.dispatch("user/removeToken");
            });
        },
        myCenter() {
            this.$router.push("/myCenter");
        },
        backHome() {
            this.$router.push("/");
        },
    },
};
</script>

<style lang="scss" scoped>
.normal {
    .hader-view {
        padding: 20px;
        position: relative;
        top: 0;
        left: 0;
        z-index: 99;
        .header-right {
            .model-box {
                line-height: 30px;
                .model-icon {
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                }
            }
        }
    }
    .dark {
        background-color: $midBackGroundColor;
    }
}

.home-page {
    .hader-view {
        padding: 20px;
        box-shadow: none !important;
        position: relative;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: rgba(
            $color: $boxBackGroundColor,
            $alpha: 0
        ) !important;
        border: none !important;
        color: $fontColorDark !important;
        .header-right {
            .model-box {
                line-height: 30px;
                .model-icon {
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    background-color: $boxBackGroundColor;
                    color: $fontColor;
                }
            }
        }
    }
}

.fiexd {
    transition: 0.8s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    .hader-view {
        padding: 20px;
        position: relative;
        top: 0;
        left: 0;
        z-index: 99;
        .header-right {
            .model-box {
                line-height: 30px;
                .model-icon {
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                }
            }
        }
    }
    .dark {
        background-color: $midBackGroundColor;
    }
}

.page-title {
    width: 340px;
}
::v-deep .el-dropdown {
    color: unset;
}
::v-deep .el-dropdown-menu__item .el-dropdown-menu__item{
    line-height: 26px;
}
</style>

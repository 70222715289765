import JSEncrypt from "jsencrypt/bin/jsencrypt.min";

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCVZnB8YHtvh+vwGq7c3iQD52ZcT5Fq/ZOqSOCRaKvTGHevKxhRWpmsQxg2yqe61jbKhK26PBVHBLOEjRPRi4C/SUctsL83VnruxC8cr6Ex+GkfUEKrBH8WtNi7iDf0GbmtZMj7N+lPPN0diCtTjsOYBuq3QnzVpvKGO3kYDDPIKQIDAQAB'

export function rsaencrypt(txt) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey); // 设置公钥
    return encryptor.encrypt(txt); // 对数据进行加密
}

// // 解密
// export function rsadecrypt(txt) {
//     const decrypt = new JSEncrypt();
//     decrypt.setPrivateKey(privateKey); // 设置私钥
//     return decrypt.decrypt(txt); // 对数据进行解密
// }
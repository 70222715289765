<template>
    <div class="app-main flex1 flex-c">
        <div class="flex1 flex-c">
            <transition name="fade-transform flex-c" mode="out-in">
                <div class="flex1 flex-c">
                    <BreadCrumb />
                    <transition name="el-fade-in-linear">
                        <router-view :key="key" class="app-container flex1 flex-c transition-box" />
                    </transition>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "VueLayout",
    components: {},
    data() {
        return {};
    },
    computed: {
        key() {
            return this.$route.path;
        },
    },
};
</script>

<style scoped lang="scss">
</style>

export default {
    namespaced: true,
    state: {
        theme: 0
    },
    getters: {
        theme: state => {
            return state.theme
        },
    },
    mutations: {
        setTheme(state, theme) {
          state.theme = theme
        },
    }
}
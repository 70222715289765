<template>
    <div class="page-boby">
        <el-container >
            <el-aside width="200px">
                <el-menu>
                    <div v-for="(item,index) in nav" :key="index">
                        <el-menu-item-group>
                            <div v-if="item.type=='1'">
                                <span class="study">学习中心</span>
                                <el-menu-item v-for="(t,key) in item.children" :key="key" :index="t.name" @click="gotoPage(item.type,t)"><i class="el-icon-message"></i>{{t.name}}</el-menu-item>
                            </div>
                            <div v-if="item.type=='2'">
                                <span class="study">求职中心</span>
                                <el-menu-item v-for="(t,key) in item.children" :key="key" :index="t.name" @click="gotoPage(item.type,t)"><i class="el-icon-message"></i>{{t.name}}</el-menu-item>

                            </div>

                        </el-menu-item-group>
                    </div>

                </el-menu>
            </el-aside>

            <div class="container">
                <router-view></router-view>
            </div>

        </el-container>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            timer: null,
            nav: [
                {
                    type: "1",
                    children: [
                        {
                            path: "MyCourse",
                            router: "@/views/examinationView/MyCourse",
                            name: "我的课程",
                        },
                        {
                            path: "MyExercise",
                            router: "@/views/examinationView/MyExercise",
                            name: "我的练习",
                        },
                        {
                            path: "MyExam",
                            router: "@/views/examinationView/MyExam",
                            name: "我的考试",
                        },
                        {
                            path: "LearningStatistics",

                            router: "@/views/examinationView/LearningStatistics",
                            name: "学习统计",
                        },
                        {
                            path: "myCertificate",
                            router: "@/views/examinationView/myCertificate",
                            name: "课程证书",
                        },
                        {
                            path: "MyOrder",
                            router: "@/views/examinationView/MyOrder",
                            name: "我的订单",
                        },
                    ],
                },
                {
                    type: "2",
                    children: [
                        {
                            path: "Jobcompetency",
                            router: "@/views/examinationView/JobcompetencyView",
                            name: "岗位能力",
                        },
                        {
                            path: "personalResume",
                            router: "@/views/examinationView/personalResume",
                            name: "个人简历",
                        },
                        {
                            path: "jobRecords",
                            router: "@/views/examinationView/jobRecords",
                            name: "求职记录",
                        },
                        {
                            path: "communicate",
                            router: "@/views/examinationView/communicateView",
                            name: "沟通",
                        },
                    ],
                },
            ],
        };
    },
    methods: {
        gotoPage(t, item) {
            for (let i = 0; i < this.nav.length; i++) {
                if (t == this.nav[i].type) {
                    for (
                        let index = 0;
                        index < this.nav[i].children.length;
                        index++
                    ) {
                        if (item.name == this.nav[i].children[index].name) {
                            this.$router.push(this.nav[i].children[index]);
                        }
                    }
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.page-boby {
    // width: 1920px;
    min-height: 1080px;
    background: #F5F8FA ;
    padding-top: 10px;
    padding-bottom: 10px;
}
.el-header {
    line-height: 60px;
}
.el-container{
    width: 80%;
    margin: auto;
}
.el-aside {
    color: #333;
    width: 20%;
    min-height: 1080px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 1px 6px rgba(103, 127, 146, 0.27);
    border-radius: 8px;
}
.container {
    width: 100%;
    background-color: #fff;
    margin-left: 20px;
    box-shadow: 0px 3px 6px rgba(103, 127, 146, 0.27);
    border-radius: 8px;
}
.study {
    width: 200px;
    padding-left: 25px;
    font-weight: bold;
    font-size: 14px;
    color: #999999;
    line-height: 22px;
    text-align: center;
}
</style>
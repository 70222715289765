<template>
    <div class="jx-flex align-center bread-crumb-page" :class="$themeStyle($store.state.theme.theme,true)">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,index) in levelList" :key="index" :to="{path:item.path}">
                {{item.name}}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            levelList: [],
        };
    },
    computed: {},
    watch: {
        $route() {
            this.getBreadcrumb();
        },
    },
    beforeMount() {
        this.getBreadcrumb();
    },
    methods: {
        getBreadcrumb() {
            this.levelList = []
            this.$route.matched.forEach((item) => {
                this.levelList.push({
                    name: item.name,
                    path: item.path,
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.bread-crumb-page{
    padding: 20px 260px;
}
::v-deep .is-link {
    color: unset;
    font-weight: normal;
}
</style>

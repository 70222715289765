const themeChoiceList = [
    'light',
    'dark'
]
const themeBoxChoiceList = [
    'light-box',
    'dark-box'
]

export function themeStyle(flag, isBox) {
    if (isBox) {
        return themeBoxChoiceList[flag]
    } else {
        return themeChoiceList[flag]

    }
}
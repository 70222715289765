import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import store from './store'
import Vuex from 'vuex'
import '@/styles/styleSheets.scss'
import 'element-ui/lib/theme-chalk/index.css';
import {
  themeStyle
} from '@/utils/themeColorConfig'
import {
  resourceType
} from '@/utils/resourceType'
import * as echarts from 'echarts';
import VueTabs from "@/components/Tabs/VueTabs.vue";
import ShowMore from './components/ShowMore.vue'
import VueCoreVideoPlayer from 'vue-core-video-player'
import BreadCrumb from './components/BreadCrumb/BreadCrumb.vue'

Vue.prototype.$echarts = echarts

Vue.component('VueTabs', VueTabs)
Vue.component('ShowMore', ShowMore)
Vue.component('BreadCrumb', BreadCrumb)
Vue.use(VueCoreVideoPlayer, {
  lang: 'zh-CN'
})
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.config.productionTip = false
Vue.prototype.$themeStyle = themeStyle
Vue.prototype.$resourceType = resourceType
Vue.prototype.$echarts = echarts;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
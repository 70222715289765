<template>
    <div :class="$themeStyle($store.state.theme.theme)" class="tabs-unit-page un-shadow flex align-center fs20 fwb">
        <div v-for="(tab,tabIndex) in tabsData" :key="tabIndex" class="tabs-box cu-point flex-c flex-j-a" :class="tabsActive === tab.id ? 'active':''" @click="changeTab(tab.id)">
            <div class="tab">
                {{tab.title}}
            </div>
            <el-image :class="tabsActive === tab.id ? 'show':'hide'" style="width: 24px; height: 6px" class="m-t-4" :src="require('../../assets/components/tabsActive.svg')" />
            <div :class="tabsActive !== tab.id ? 'show':'hide'" style="width: 24px; height: 6px" class="m-t-4" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
        tabsActive: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {};
    },
    methods: {
        changeTab(id) {
            this.$emit("changeTab", id);
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs-unit-page {
    .tabs-box {
        margin-right: 30px;
    }
    .active {
        .tab {
            transition: 0.5s;
            color: $themeColor;
        }
    }
    .hide {
        opacity: 0;
        transition: 0.5s;
    }
    .show {
        opacity: 1;
        transition: 0.5s;
    }
}
.light {
    .tab {
        transition: 0.5s;
        color: $darkSubFontColor;
    }
}
</style>
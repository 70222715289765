import request from '@/utils/request'
//data.isEncrypt = 1 数据是否加密，加密需要再data里面加上这个参数，不加密不需要做任何操作
//账号登录
export function login(data) {
  data.isEncrypt = 1
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
//获取验证码
export function getCode(data) {
  return request({
    url: '/user/sms/verifyCode',
    method: 'post',
    data
  })
}
//验证码登录
export function codeLogin(data) {
  data.isEncrypt = 1
  return request({
    url: '/user/verifyCodeLogin',
    method: 'post',
    data
  })
}

export function currentUser() {
  return request({
    url: '/user/loginInfo',
    method: 'get'
  })
}

export function logout() {
  return request({      
    url: '/auth/user/logout/token',
    method: 'post'
  })
}

export function getTeacherDepartment() {
  return request({
    url: '/base/department/teacherDepartment',
    method: 'post'
  })
}

export function getSchoolDetail() {
  return request({
    url: '/base/school/detail',
    method: 'get'
  })
}

export function getAuthBtn(data) {
  return request({
    url: '/base/menu/findMenuButton',
    method: 'post',
    data
  })
}

export function getMenus(params) {
  return request({
    url: '/base/menu/findUserMenuTree',
    method: 'get',
    params
  })
}

export function getAccountTypes() {
  return request({
    url: '/base/user/findUserTypeList',
    method: 'get'
  })
}
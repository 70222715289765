<template>
  <div class="show-more">
    <div
      class="just-font cu-point fs16"
      :class="$themeStyle($store.state.theme.theme)"
      @click="showMore()"
    >
      查看更多
      <span class="iconfont el-icon-arrow-right"></span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showMore() {
      this.$emit("showMore");
    },
  },
};
</script>

<style lang="scss" scoped>
.show-more {
  .light {
    color: $greyFontColor;
  }
}
</style>

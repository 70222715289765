import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'
import {
  getToken
} from '@/utils/auth'
import VueLayout from '@/components/VueLayout.vue'
import examinationView from '@/views/examinationView/examinationView.vue'
import {
  Message
} from 'element-ui'
Vue.use(Router)

export const constantRoutes = [{
    path: '/login',
    component: () => import('@/views/login/index'),
    name: '登录',
    hidden: true,
    meta: {
      needBottom: '1'
    }
  },
  {
    path: '/register',
    component: () => import('@/views/login/Register'),
    name: '注册',
    hidden: true,
    meta: {
      needBottom: '1'
    }
  },
  {
    path: '/home',
    component: () => import('@/views/home/Home'),
    name: '首页'
  },
  {
    path: "/message",
    component: () => import('@/views/messageView/messageView'),
    name: '消息',
  },
  {
    path: "/examina",
    component: examinationView,
    name: '学习中心',
    redirect: '/examina/MyCourse',
    children: [{
        path: "MyCourse",
        component: () => import('@/views/examinationView/MyCourse'),
        name: '我的课程',
        // meta: {
        //   requiresLogin: true
        // }
      },
      {
        path: "MyExercise",
        component: () => import('@/views/examinationView/MyExercise'),
        name: '我的练习'
      },
      {
        path: "MyExam",
        component: () => import('@/views/examinationView/MyExam'),
        name: '我的考试'
      },
      {
        path: "LearningStatistics",
        component: () => import('@/views/examinationView/LearningStatistics'),
        name: '学习统计'
      },
      {
        path: "myCertificate",
        component: () => import('@/views/examinationView/myCertificate'),
        name: '课程证书'
      },
      {
        path: "MyOrder",
        component: () => import('@/views/examinationView/MyOrder'),
        name: '我的订单'
      },
      {
        path: "Jobcompetency",
        component: () => import('@/views/examinationView/JobcompetencyView'),
        name: '岗位能力'
      },
      {
        path: "personalResume",
        component: () => import('@/views/examinationView/personalResume'),
        name: '个人简历'
      },
      {
        path: "jobRecords",
        component: () => import('@/views/examinationView/jobRecords'),
        name: '求职记录'
      },
      {
        path: "communicate",
        component: () => import('@/views/examinationView/communicateView'),
        name: '沟通'
      },

    ]
  },
  {
    path: "/studyOnline",
    component: () => import('@/views/study/File.vue'),
    name: '视频组件',
    meta: {
      needBottom: '1'
    }
  },
  {
    path: "/personalCenter",
    component: () => import('@/views/pensonalCenter/homePage.vue'),
    name: '个人中心',
    redirect: '/personalCenter/personalData',
    children: [{
        path: "personalData",
        component: () => import('@/views/pensonalCenter/personalData'),
        name: '个人资料'
      },
      {
        path: "accountSecurity",
        component: () => import('@/views/pensonalCenter/accountSecurity'),
        name: '账号安全'
      },
      {
        path: "cancelAccount",
        component: () => import('@/views/pensonalCenter/cancelAccount'),
        name: '注销账号'
      }
    ]
  },
  {
    path: "/messageNotification",
    component: () => import('@/views/MsgNotification/MsgPage.vue'),
    name: '个人中心',
    redirect: '/messageNotification/QuestionsAndAnswers',
    children: [{
        path: "QuestionsAndAnswers",
        component: () => import('@/views/MsgNotification/QuestionsAndAnswers'),
        name: '问答通知'
      },
      {
        path: "MiscussionGroup",
        component: () => import('@/views/MsgNotification/MiscussionGroup'),
        name: '讨论组'
      },
      {
        path: "ExamNotice",
        component: () => import('@/views/MsgNotification/ExamNotice'),
        name: '考试通知'
      },
      {
        path: "CorrectionNotice",
        component: () => import('@/views/MsgNotification/CorrectionNotice'),
        name: '批改通知'
      },
      {
        path: "OrderNotification",
        component: () => import('@/views/MsgNotification/OrderNotification'),
        name: '订单通知'
      }
    ]
  },
  {
    path: "/student",
    component: VueLayout,
    redirect: '/student/courseList',
    name: '课程中心',
    meta: {
      requiresLogin: true
    },
    children: [{
        path: "courseList",
        component: () => import('@/views/student/course/courseList'),
        name: '课程中心',
        meta: {
          // requiresLogin: true
        }
      },
      {
        path: "courseDetails",
        component: () => import('@/views/student/course/courseDetails'),
        name: '课程详情'
      },
    ]
  },
  {
    path: "/myCenter",
    component: () => import('@/views/student/myCenter/myCenter.vue'),
    name: '个人中心',
  },
  {
    path: '/',
    redirect: '/home',
    hidden: false
  },
]
export const RoutesStu = [{}]
const createRouter = () => new Router({
  routes: constantRoutes
})

const router = createRouter()
// const hasToken = getToken()

router.beforeEach((to, from, next) => {
  const noNeedToken = ['/register', '/login', '/home']
  //没有token的情况
  if (!getToken()) {
    if (noNeedToken.includes(to.path) || !to.meta.requiresLogin) {
      next()
    }
    if (to.meta.requiresLogin) {
      Message.warning('需要登录才能访问该页面')
      next({
        path: 'login'
      })
    }
  } else {
    //有token的情况
    if (!store.state.user.user.id) { //刷新页面vuex数据丢失
      store.dispatch('user/getUserInfo')
    }
    next()
  }
})

export default router
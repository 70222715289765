//封装axios
import axios from "axios"
import {
    aesdecrypt,
    aesencrypt,
    getKeyAES
} from "@/utils/aes.js"
import {
    rsaencrypt
} from "@/utils/rsa.js"
import store from '@/store'
import {
    Message
} from 'element-ui'
import {
    getToken
} from '@/utils/auth'
const url = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API : process.env.VUE_APP_API_ONLINE
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 0, // 10 * 1000 // request timeout 10s
})

service.interceptors.request.use(
    async config => {
            config.url = url + config.url
            // if (config.data.isLogin) {
            //     getToken()
            // }
            //不是post请求 一律返回
            if (config.method !== 'post') {
                const token = getToken()
                if (token) {
                    config.headers['Authorization'] = token
                }
                return config
            }
            //给PC端请求加头，方便后面移动端开发
            config.headers['X-Custom-Application-Category'] = 'pc'
            //判断数据是否加密
            if (config.data["isEncrypt"] && process.env.VUE_APP_OPENENCRYPT == '1') {
                //用aes对数据进行加密
                let i = getKeyAES()
                let k = getKeyAES()
                let s = aesencrypt(config.data, k, i)
                config.data = {
                    secretStr: s + rsaencrypt(k) + rsaencrypt(i)
                }
                return config
            }
            return config
        },
        error => {
            // do something with request error
            console.log(error) // for debug
            return Promise.reject(error)
        }
)

service.interceptors.response.use((response) => {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        const {
            data
        } = response
        //请求报错
        if (data.code != 200) {
            Message.error(data.message || '登陆失败')
            store.dispatch('user/removeToken').then(() => {})
            return Promise.reject(new Error(data.message || 'Error'))
        } else {
            return data
        }
    },

    error => {
        const response = error.response
        if (response) {
            if (response.status === 401) {
                Message.error('权限认证失败')
                store.dispatch('user/resetToken').then(() => {
                    location.reload()
                })
                return
            }
            Message.error('服务器异常,请稍后再试！')
            return Promise.reject(error)
        }
        Message.error(error.message)
        return Promise.reject(error)
    }
)
export default service
import Cookies from 'js-cookie'
const tokenKey = 'qypb-token'
export function getToken() {
  return Cookies.get(tokenKey)
}

export function setToken(token) {
  return Cookies.set(tokenKey, `Bearer ${token.tokenValue}`)
}

export function removeToken(token) {
  return Cookies.remove(tokenKey)
}
//数据加密
import CryptoJS from 'crypto-js';
//解密
export function aesdecrypt(data, AES_KEY, IV) {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY);
  const iv = CryptoJS.enc.Utf8.parse(IV);
  const decrypt = CryptoJS.AES.decrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
  return decrypt;
}
// 加密
export function aesencrypt(data, AES_KEY, IV) {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY);
  const iv = CryptoJS.enc.Utf8.parse(IV);
  const src = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  const encrypted = CryptoJS.AES.encrypt(src, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}
export function getKeyAES() {
  const key = []
  for (let i = 0; i < 16; i++) {
    const num = Math.floor(Math.random() * 26)
    const charStr = String.fromCharCode(97 + num)
    key.push(charStr.toUpperCase())
  }
  const result = key.join('')
  return result
}
